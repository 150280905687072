import * as React from 'react';
import * as THREE from 'three';

import { TimelineLite } from 'gsap';
import { Image } from 'components/image/Image';
import { useInView } from 'react-intersection-observer';

import {
  fragment,
  vertex,
} from './shaders';

import {
  reach,
} from './reach';

import {
  GLManager,
} from './GLManager';

import s from './Globe.scss';

interface IGlobeProps {
  children: React.ReactNode;
}

export const Globe = ({ children }: IGlobeProps) => {
  const refContent = React.useRef<HTMLDivElement>(null);
  const refHeading = React.useRef<HTMLHeadingElement>(null);
  const [ref, inView] = useInView({ triggerOnce: true, rootMargin: '-10% 0%'  });

  // const GL = new GLManager();
  // GL.createPlane();
  //
  // let followerSpring = null;
  // const follower = {
  //   x: 0,
  //   y: 0,
  // };
  //
  // const mouseMoveHandler = (ev) => {
  //   if (followerSpring) {
  //     followerSpring.stop();
  //     followerSpring = null;
  //   }
  //
  //   followerSpring = reach({
  //     from: {
  //       x: follower.x,
  //       y: follower.y,
  //     },
  //     to: {
  //       x: ev.clientX,
  //       y: ev.clientY,
  //     },
  //     velocity: {
  //       x: follower.vx,
  //       y: follower.vy,
  //     },
  //     stiffness: 500,
  //     damping: 50,
  //     mass: 1,
  //   }).start({
  //     update: (position) => {
  //       const velocity = {
  //         x: position.x - follower.x,
  //         y: position.y - follower.y,
  //       };
  //       GL.updateRgbEffect({
  //         position,
  //         velocity,
  //       });
  //       this.follower = {
  //         x: position.x,
  //         y: position.y,
  //         vx: velocity.x,
  //         vy: velocity.y,
  //       };
  //     },
  //     complete: () => {
  //       GL.updateRgbEffect({
  //         position: follower,
  //         velocity: {
  //           x: 0,
  //           y: 0,
  //         },
  //       });
  //       follower.vx = 0;
  //       follower.vy = 0;
  //     },
  //   });
  // };
  //
  // React.useEffect(() => {
  //   if (!refContent.current) { return; }
  //
  //   refContent.current.addEventListener('mousemove', mouseMoveHandler);
  //   return () => {
  //     if (refContent.current) {
  //       refContent.current.removeEventListener('mousemove', mouseMoveHandler);
  //     }
  //   };
  // }, []);

  React.useEffect(() => {
    if (true) {
      const t = new TimelineLite();

      t.addLabel('start', 0.2);

      if (refContent.current) {
        t.fromTo(
          refContent.current,
          1.3,
          { autoAlpha: 0, y: '30px' },
          { autoAlpha: 1, y: '0px', ease: 'Power4.easeInOut' },
          'start+=0.2',
        );
      }

      if (refHeading.current) {
        t.fromTo(
          refHeading.current,
          1.3,
          { autoAlpha: 0 },
          { autoAlpha: 1, ease: 'Power4.easeInOut' },
          'start+=0.5',
        ).call(() => {
          t.set(
            refHeading.current,
            { clearProps: 'transform' },
          );
        });
      }

    }
  }, []);

  return (
    <div className={s.globe} ref={ref}>
      <h1 className={s.globe__heading} ref={refHeading}>
        Malbygg is a craft brewery founded in Reykjavik, Iceland
      </h1>
      <div className={s.globe__content}>
        <div className={s.globe__art} ref={refContent}>
          <Image
            className={s.globe__image}
            src={require('assets/images/globe.png')}
          />
        </div>
        <div className="content" id="app" />
      </div>
    </div>
  );
};
