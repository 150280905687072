import * as React from 'react';
import Helmet from 'react-helmet';

import { Globe } from 'components/globe/Globe';

export default () => (
  <>
    <Helmet title="Home" />

    <Globe />

  </>
);
